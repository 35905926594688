/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-catch */

export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REFRESH = "AUTH_REFRESH";
export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";
export const GET_IP = "GET_IP";
export const SET_IP = "SET_IP";
export const SET_LOADING_STATE = "SET_LOADING_STATE";

import axios from 'axios';

import AuthService from "@/api/auth.js"

const state = {
  token: localStorage.getItem("user-token") || "",
  ip: null,
  loadingState: false,
  currentlyLoading: "",
  status: "",
  hasLoadedOnce: false,
};

const getters = {
  getToken: (s) => s.token,
  ip: (s) => s.ip,
  isAuthenticated: (s) => !!s.token,
  authStatus: (s) => s.status,
  loadingState: (s) => s.loadingState,
  currentlyLoading: (s) => s.currentlyLoading
 };

const actions = {
  async login({ commit, dispatch }, { email, password }) {
    try{
      let resp = await AuthService.login({email, password})
      localStorage.setItem("user-token", "success");
      dispatch(GET_IP).then()
      commit(AUTH_SUCCESS, resp);
    }catch (error){
      commit(AUTH_ERROR, error);
      localStorage.removeItem("user-token");
      throw error
    }
  },
  [GET_IP]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios.get("https://api64.ipify.org?format=json").then(resp => {
        commit(SET_IP, resp);
        resolve(resp)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  async getLoginToken({ commit, dispatch }, { email, password }){
    try{
      await AuthService.getLoginToken({ email, password })
    }catch (err){
      throw err
    }
  },
  async logout({ commit, dispatch }) {
    try{
      let resp = await AuthService.logout()
      commit(AUTH_LOGOUT);
      localStorage.removeItem("user-token");
    }catch (error){
      throw(error)
    }
  },
};

const mutations = {
  [AUTH_REQUEST]: (requestState) => {
    const s = requestState;
    s.status = "loading";
  },
  [SET_IP]: (s, resp) => {
    s.ip = resp.data.ip
  },
  [AUTH_SUCCESS]: (s, resp) => {
    s.status = "success";
    s.token = "success";
    s.hasLoadedOnce = true;
  },
  [START_LOADING]: (s) => {
    s.loadingState = true;
  },
  [SET_LOADING_STATE]: (s, current) => {
    s.currentlyLoading = current;
  },
  [FINISH_LOADING]: (s) => {
    s.loadingState = false;
  },
  [AUTH_ERROR]: (errorState) => {
    const s = errorState;
    s.status = "error";
    s.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (logoutState) => {
    const s = logoutState;
    s.token = "";
  },
};


export default {
  state,
  getters,
  actions,
  mutations
};
