// import Form from "@/components/ui/Form.vue";
// import Title from "@/components/ui/Title.vue";
// import Page from "@/components/ui/Page.vue";
// import PrettyNumber from "@/components/ui/PrettyNumber.vue";
// import BCurrencyWithValidation from "@/components/validation/BCurrencyWithValidation.vue";
// import BDateWithValidation from "@/components/validation/BDateWithValidation.vue";
import BInputWithValidation from "@/components/validation/BInputWithValidation.vue";
// import BTreeWithValidation from "@/components/validation/BTreeWithValidation.vue";
// import BSelectWithValidation from "@/components/validation/BSelectWithValidation.vue";
// import BNumberWithValidation from "@/components/validation/BNumberWithValidation.vue";
// import BPercentageWithValidation from "@/components/validation/BPercentageWithValidation.vue";
//
// import Treeselect from '@riophae/vue-treeselect'
// import CountryFlag from 'vue-country-flag'

// import vSelect from 'vue-select'

import { ValidationObserver } from "vee-validate";
import Vue from 'vue'

// leave the export, even if you don't use it
// Vue.component("BNumberWithValidation", BNumberWithValidation);
// Vue.component("BCurrencyWithValidation", BCurrencyWithValidation);
// Vue.component("BDateWithValidation", BDateWithValidation);
// Vue.component("BTreeWithValidation", BTreeWithValidation);
Vue.component("BInputWithValidation", BInputWithValidation);
// Vue.component("BSelectWithValidation", BSelectWithValidation);
// Vue.component("BPercentageWithValidation", BPercentageWithValidation);
Vue.component("ValidationObserver", ValidationObserver);
// Vue.component('treeselect', Treeselect)
// Vue.component("Form", Form);
// Vue.component("Page", Page);
// Vue.component("PrettyNumber", PrettyNumber);
// Vue.component("Title", Title);
// Vue.component('v-select', vSelect)
// Vue.component('country-flag', CountryFlag)
