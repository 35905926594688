/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-catch */


import DefaultService from "@/api/default.js";

const state = {
};

const getters = {
 };

const actions = {
  async pingFast({ commit, dispatch }) {
    try{
      let resp = await DefaultService.pingFast()
      console.log(resp)
    }catch (error){
      throw(error)
    }
  },
  async pingDjango({ commit, dispatch }) {
    try{
      let resp = await DefaultService.pingDjango()
      console.log(resp)
    }catch (error){
      throw(error)
    }
  }
};


const mutations = {
};


export default {
  state,
  getters,
  actions,
  mutations
};
