<template>
  <section>
  
    <b-tabs
      :position="atRight ? 'is-right' : ''"
      :size="size"
      :type="type"
      vertical
      :animated=false
      :expanded="expanded">

      <b-tab-item label="Profile" headerClass="user-profile">

        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <section class="section mb-50">
            <div class="max-input-width">
              <h2 class="mb-30">Account details</h2>
              <UserInfoTable />
            </div>
          </section>

          <section class="section mb-50">
            <div class="max-input-width">
              <h2 class="mb-30">Update your personal details</h2>
              <BInputWithValidation
                rules="required|name"
                type="text"
                class="mb-12 d-block"
                vid="name"
                label="Full name"
                :placeholder="'Name'"
              />
              <BInputWithValidation
                rules="required|email"
                type="email"
                class="mb-20 d-block"
                vid="email"
                label="Your email"
                :placeholder="'Email'"
              />
              <b-field
                class="mb-20"
                label="Contact preferences">
                  <b-field class="d-inline-block checkbox-block">
                    <b-checkbox class="pl-0">Send me emails about my account</b-checkbox>
                    <b-checkbox class="pl-0">Send newsletters and special offers</b-checkbox>
                    <b-checkbox class="pl-0">Send me tips on getting started</b-checkbox>
                  </b-field>
              </b-field>

              <div class="buttons mb-6">
                <b-button
                  type="submit-button"
                  class="button is-primary"
                  @click="handleSubmit(submit)">
                  Save personal details
                </b-button>
              </div>
            </div>
          </section>
        </ValidationObserver>

        <ValidationObserver ref="form" v-slot="{  }">
          <section class="section">
            <div class="max-input-width">
              <h2 class="mb-30">Change your password</h2>
              <BInputWithValidation
                rules="required"
                type="password"
                class="mb-12 d-block"
                v-model="currentPassword"
                label="Current password"
                :placeholder="'Current password'"
              />
              <BInputWithValidation
                rules="required"
                type="password"
                class="mb-12 d-block"
                v-model="newPassword"
                label="New password"
                :placeholder="'New password'"
              />
              <BInputWithValidation
                rules="required"
                type="password"
                class="mb-20 d-block"
                v-model="confirmNewPassword"
                label="Confirm new password"
                :placeholder="'Confirm new password'"
              />
              <b-notification
                  v-model="passwordsNotMatch"
                  aria-close-label="Close notification">
                  passwords does not match
                </b-notification>
              <div class="buttons mb-6">
                <button
                  class="button is-primary"
                  @click="updateUserPassword(
                    {
                      current_password: currentPassword,
                      new_password: newPassword,
                      confirm_new_password: confirmNewPassword
                    }
                  )">
                  Change password
                </button>
              </div>
            </div>
          </section>
        </ValidationObserver>
      </b-tab-item>

      <b-tab-item label="Add/Remove/View API keys" headerClass="api">
        <section class="section mb-30 no-background no-shadow no-border">
          <ModalRoot />
          <button @click="toggleModal" class="button is-default mb-20">Add API key</button>
          <button @click="listAllApiKeys" class="button is-default mb-20">Refresh API keys</button>
          <button @click="deleteManyApiKeys({checked_rows: checkedRows})" class="button is-default mb-20">Delete selected API keys</button>

          <b-table
              :data="getAllApiKeys"
              :checked-rows.sync="checkedRows"
              checkable
              :checkbox-position="checkboxPosition">
              <template v-for="column in columns">
              <b-table-column :key="column.id" v-bind="column">
                <template
                  v-if="column.searchable && !column.numeric"
                  #searchable="props">
                  <b-input
                      v-model="props.filters[props.column.field]"
                      placeholder="Search..."
                      icon="magnify"
                      size="is-small" />
                </template>
                <template v-slot="props">
                  {{ props.row[column.field] }}
                </template>
              </b-table-column>
            </template>
          </b-table>
        </section>
      </b-tab-item>
    </b-tabs>

  </section>
</template>


<script>
import ModalRoot from '../components/Modals/ModalRoot.vue';
import ModalService from '../components/Modals/modal.service';
import ApiKeyModal from '../components/UserProfile/ApiKeyModal';
import UserInfoTable from '../components/UserProfile/UserInfoTable';
import { mapActions, mapGetters} from 'vuex';

export default {
  name: "UserProfile",
  components: { ModalRoot, UserInfoTable },
  computed: {
    ...mapGetters(['getAllApiKeys', 'user_details_ls']),
    passwordsNotMatch: function() {
      return this.newPassword !== this.confirmNewPassword;
    }
  },
  methods: {
    ...mapActions(['listAllApiKeys', 'deleteManyApiKeys', 'updateUserPassword']),

    toggleModal() {
      ModalService.open(ApiKeyModal);
    },

  },

  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',

      checkedRows: [],
      checkboxPosition: 'left',
      columns: [
        {
          field: '_id',
          label: '_id',
          searchable: true,
        },
        {
          field: 'name',
          label: 'Key name',
          searchable: true,
        },
        {
          field: 'exchange_broker',
          label: 'Exchange & Broker',
          searchable: true,
        },
        {
          field: 'pub_key',
          label: 'Hashed public key',
        },
        {
          field: 'priv_key',
          label: 'Hashed private key',
        },
      ],

      // Vertical tabs
      expanded: false,
      atRight: false,
      size: null,
      type: null,
      animated: false

    }
  },
}

</script>

<style scoped>
</style>
