<template>
  <section>
    <div class="container is-fluid">
        <div class="notification is-seconday">
          <b-field label="Find an Instrument">
              <b-autocomplete
                  v-model="name"
                  placeholder="btc/usd"
                  :keep-first=true
                  :open-on-focus=false
                  :data="filteredDataObj"
                  field="broker_name"
                  @select="option => (selected_instrument = Object.entries(option))"
                  :clearable=true>
                  <template slot-scope="props">
                        <div class="media">
                            <div class="media-left" style="min-width: 80px">
                                {{ props.option.broker }}
                            </div>
                            <div class="media-content" style="min-width: 80px">
                                {{ props.option.broker_name }}
                            </div>
                        </div>
                    </template>
              </b-autocomplete>
          </b-field>
        </div>
        <div class="columns">
            <div class="column">
              <b-table
                  :data="isEmpty ? [] : selected_instrument"
                  :bordered=false
                  :striped=false
                  :narrowed=true
                  :hoverable=true
                  :loading=false
                  :focusable=false
                  :mobile-cards=false>

                  <b-table-column field="_id" label="_id" width="180" :td-attrs="columnTdAttrs" numeric v-slot="props">
                      {{ props.row[0] }}
                  </b-table-column>

                  <b-table-column field="value" label="value" :td-attrs="columnTdAttrs" v-slot="props">
                      {{ props.row[1] }}
                  </b-table-column>

      <!--            <b-table-column field="last_name" label="Last Name" :td-attrs="columnTdAttrs" v-slot="props">-->
      <!--                {{ props.row.last_name }}-->
      <!--            </b-table-column>-->
      <!--            <b-table-column field="date" label="Date" :th-attrs="dateThAttrs" :td-attrs="columnTdAttrs" centered v-slot="props">-->
      <!--                <span class="tag is-success">-->
      <!--                    {{ new Date(props.row.date).toLocaleDateString() }}-->
      <!--                </span>-->
      <!--            </b-table-column>-->
      <!--            <b-table-column label="Gender" :td-attrs="columnTdAttrs" v-slot="props">-->
      <!--                <span>-->
      <!--                    <b-icon-->
      <!--                        v-if="props.row.id !== 'Total'"-->
      <!--                        pack="fas"-->
      <!--                        :icon="props.row.gender === 'Male' ? 'mars' : 'venus'">-->
      <!--                    </b-icon>-->
      <!--                    {{ props.row.gender }}-->
      <!--                </span>-->
      <!--            </b-table-column>-->
                  <template #empty>
                      <div class="has-text-centered">No records</div>
                  </template>
              </b-table>
            </div>
            <div class="column">
              <h1>Test Page</h1>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
// import { ref } from '@vue/composition-api';
import {fast} from '../api/api';

export default {
  name: 'Instruments',
  components: {
  },

  data() {
    return {
      keepFirst: false,
      openOnFocus: false,
      name: '',
      isEmpty: false,
      selected_instrument: [],
      clearable: false,
      instrument_ls: [],
      dropdownMenu: undefined
    }
  },

  methods: {
    dateThAttrs(column) {
        return column.label === 'Date' ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: 'has-text-success'
        } : null
    },
    columnTdAttrs(row, column) {
        if (row.id === 'Total') {
            if (column.label === 'ID') {
                return {
                    colspan: 4,
                    class: 'has-text-weight-bold',
                    style: {
                        'text-align': 'left !important'
                    }
                }
            } else if (column.label === 'Gender') {
                return {
                    class: 'has-text-weight-semibold'
                }
            } else {
                return {
                    style: {display: 'none'}
                }
            }
        }
        return null
    }
  },

  computed: {
    filteredDataObj() {
      return this.instrument_ls.filter(option => {
        if (option.broker_name === undefined) {
          option.broker_name = " "
        }
        return (
          option.broker_name
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
        )
      })
    },
  },

  setup() {
  },

  created: function() {
  },

  mounted() {
    this.dropdownMenu = async () => {
      try {
        let resp = await fast.get('instruments/all')
        // console.log((resp.instrument_ls))
        this.instrument_ls = resp.data
      } catch (err) {
        console.log(err)
        console.log('Get instrument data resulted in error')
      }
    }
    this.dropdownMenu()
  }

}

</script>

<style scoped>
</style>