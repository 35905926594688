<template>
  <div class="">
    <div class="panel-top-banner">
      <h1 class="mb-40">The ultimate tool for digital asset analytics</h1>
      <p class="mb-60">eXtapose offers an intuitive and modular suite of solutions across the spectrum of digital asset investing. Targeted and easy onboarding, including process design that takes into account the specifics of digital assets and compliance considerations.</p>
      <a href="#sign-up" class="button button-secondary">Sign up for updates</a>
    </div>

    <div class="pres-section">
      <div class="container has-text-centered">
        <div class="exta-logo-anim">
        <img src="../assets/exta-1.png">
        <img src="../assets/exta-2.png">
        <!-- <img src="../assets/extapose-logo.svg"> -->
        </div>
        <h2 class="mb-40">Your focus should always be on investment decisions, not market structure, system capabilities or process setup</h2>
        <p class="mb-60">The team at eXtapose are a mix of traders, researchers and designers who wanted a purpose built platform for professional and institutional use cases.</p>
        <p class="custom-screen">
          <img class="mb-0 custom-screen" src="../assets/app-screen.svg">
        </p>
      </div>
    </div>

    <div class="pres-section pres-section-darkred">
      <div class="container">
        <HPCarousel></HPCarousel>
        <h2 class="mb-40 max-read-width">Highly scalable, versatile and modular</h2>
        <p class="max-read-width">eXtapose is a SAAS native platform built from start with scalability, performance and versatility in mind. The user interface is designed to optimize process and execution efficiency. This is further supported by primary research and domain know-how to ensure competitiveness.</p>
      </div>
    </div>

    <div class="pres-section pb-60">
      <div class="container">
        <div class="is-flex is-justify-content-space-between">
          <div class="contact-box-1">
            <img class="mb-20 is-block" src="../assets/extapose-logo.svg">
            <a href="mailto:info@extapose.com">info@extapose.com</a>
          </div>
          <div class="contact-box-2">
            <h4 id="sign-up" class="mb-30 font-family-ubuntu">Sign up for updates</h4>
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <BInputWithValidation
                  type="name"
                  class="mb-16 d-block"
                  vid="name"
                  label="Name"
                  v-model="name"
                  :placeholder="'Name'"
                />
                <BInputWithValidation
                  rules="required|email"
                  type="email"
                  class="mb-20 d-block"
                  vid="email"
                  label="Email"
                  v-model="email"
                  :placeholder="'Email address'"
                />
                <div class="buttons">
                  <b-button
                    type="button button-tertiary"
                    @click="handleSubmit(submit)">
                    Contact us!
                  </b-button>
                </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <footer class="container">
      <div>
        <p>Copyright © 2022 eXtapose. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>



<script>
  import HPCarousel from '../components/Carousel/HPCarousel';

  export default {
    name: 'Home',
    // setup(props, { root }){
    //   const form = ref(null)
    //   const email = ref(null)
    //   const name = ref(null)

    //   const { Contact } = useActions(['contact'])

    //   const submit = async() => {
    //     console.log("trying to sumbit")
    //     try {
    //       const data = {
    //         email: email.value,
    //         name: name.value
    //       }
    //       console.log(data)
    //       await contact(data, data)
    //     } catch (err){
    //       console.log("sumbit resulted in error")
    //       console.log(err)
    //     } finally {
    //         root.$router.push({name: 'Home'})
    //     }
    //   }
    //   return {
    //     form,
    //     email,
    //     name,
    //     submit
    //   }
    // },

    components: {
      HPCarousel,
    },

    mounted() {
      var $ = require("jquery");
      $(window).scroll(function() {
        var x = $(".exta-logo-anim").offset();
        var imageDivscroll = x.top;
        var Wheight = $(window).height() / 1.5;

        if ($(window).scrollTop() >= imageDivscroll - Wheight) {
          $('.exta-logo-anim').addClass('animate');
        } else {
          $('.exta-logo-anim').removeClass('animate');
        }
      });
    }
  }
</script>


<style scoped>
</style>

