// -*- coding: utf-8 -*-
// @author: Eric Melcher [em], Created on 08/01/2022
// IMPORTS -------------------------------------------------------------------------------------------------------------

/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-catch */
import apiKeysService from '@/api/apiKeys.js';

const UPDATE_KEY_LIST = 'UPDATE_KEY_LIST';
const UPDATE_CHECKED_KEY_LIST = 'UPDATE_CHECKED_KEY_LIST';


export default {
  // namespaced: true,

  state: {
    keys_ls: [],
    keys_obj: {},
    checked_keys_ls: [],
  },

  getters: {
    getAllApiKeys(state) {
      return state.keys_ls
    }
  },

  mutations: {
    [UPDATE_KEY_LIST](state, data) {
      state.keys_ls = data
      state.keys_obj = data.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
    },
  },

  actions: {
    async listAllApiKeys({ commit, dispatch }) {
      try{
        let response = await apiKeysService.listAllApiKeys()
        console.log(response.data)
        commit(UPDATE_KEY_LIST, response.data)
        return response
      }catch (error){
        throw(error)
      }
    },
    async addApiKey({ commit, dispatch }, { name, exchange_broker, pub_key, priv_key }) {
      try{
        let response = await apiKeysService.addApiKey({name, exchange_broker, pub_key, priv_key})
        console.log(response)
        return response
      }catch (error){
        throw(error)
      }
    },
    async deleteApiKey({ commit, dispatch }, {_id}) {
      try{
        let response = await apiKeysService.deleteApiKey({_id})
        console.log(response)
        return response
      }catch (error){
        throw(error)
      }
    },
    async deleteManyApiKeys({ commit, dispatch }, {checked_rows}) {
      try{
        let id_ls = checked_rows.map(a => a._id)
        let response = await apiKeysService.deleteManyApiKeys({id_ls})
        console.log(response)
        return response
      }catch (error){
        throw(error)
      }
    }
  },
};