<template>
  <div class="container is-max-desktop">

    <div class="card" style="width: 550px;">
      <div class="card-header">
        ping-django
      </div>
      <div class="card-content">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <div class="buttons is-centered mt-6">
            <b-button
              type="is-primary"
              @click="handleSubmit(submitDjango)">
              {{ $t('send') }}
            </b-button>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <div class="card" style="width: 550px;">
      <div class="card-header">
        ping-fast
      </div>
      <div class="card-content">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <div class="buttons is-centered mt-6">
            <b-button
              type="is-primary"
              @click="handleSubmit(submit)">
              {{ $t('send') }}
            </b-button>
          </div>
        </ValidationObserver>
      </div>
    </div>

  </div>
</template>

<script>
  import { ref } from '@vue/composition-api';
  import { useActions } from 'vuex-composition-helpers';

  export default {

    name: "PingPong",
    // eslint-disable-next-line no-unused-vars
    setup(props, { root }){
      let responsedjango = ref(null)
      let responsefast = ref(null)
      const { pingDjango } = useActions(['pingDjango'])
      const { pingFast } = useActions(['pingFast'])


      const submitDjango = async() => {
        console.log("trying to sumbit to /ping")
        try {
          responsedjango = await pingDjango()
        } catch (err_django) {
          console.log("sumbit resulted in error")
          console.log(err_django)
        } finally {
          console.log(responsedjango)
        }
      }

      const submit = async() => {
        console.log("trying to sumbit to /ping")
        try {
          responsefast = await pingFast()
        } catch (err) {
          console.log("sumbit resulted in error")
          console.log(err)
        } finally {
          console.log(responsefast)
        }
      }

      return {
        responsedjango,
        responsefast,
        submitDjango,
        submit
      }
    },
    // methods: {
    //   alert() {
    //     this.$buefy.dialog.alert()
    //   }
    // },
  }

</script>

<style scoped>

</style>