// BASIC DEPENDENCIES
import Vue from 'vue';
import App from './App.vue';
import Buefy from 'buefy';
import VueCompositionAPI from '@vue/composition-api';

// STYLING
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'buefy/dist/buefy.css';
import '@/styles/index.scss?v=123';

// ROUTING & FUNCTIONALITY
import router from './router/router.js';
import { store } from '@/store';
import '@/utils/components';
import "@/utils/vee-validate";
import "@/utils/modules-grid"; 


import VueGoodTablePlugin from 'vue-good-table';
// IMPORT THE STYLES
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

// OTHER
import i18n from '@/utils/i18n.js';

Vue.use(Buefy);
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

new Vue(
    {
      render: h => h(App),
      router,
      store,
      i18n
    }
).$mount('#app');

