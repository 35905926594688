// -*- coding: utf-8 -*-
// @author: Eric Melcher [em], Created on 17/10/2021
// IMPORTS -------------------------------------------------------------------------------------------------------------
import {api} from './api';

const END_POINT = 'users';
const CREATE = 'create';
const DETAILS = 'details';
const UPDATE_PASSWORD = 'update-pw';
const RESET_PASSWORD = 'reset-pw';

class UsersService{

    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    createUser(data) {
        console.log(data)
        return api.post(`${END_POINT}/${CREATE}`, data)
    }

    getUserDetails() {
        return api.get(`${END_POINT}/${DETAILS}`)
    }

    updateUserPassword(data) {
        return api.post(`${END_POINT}/${UPDATE_PASSWORD}`, data)
    }

    resetUserPassword(data) {
        return api.post(`${END_POINT}/${RESET_PASSWORD}`, data)
    }

}

export default new UsersService(END_POINT)

