// -*- coding: utf-8 -*-
// @author: Eric Melcher [em], Created on 08/01/2022
// IMPORTS -------------------------------------------------------------------------------------------------------------

import Vue from 'vue';
export default new Vue({
  methods: {
    open(component, props = {}) {
      return new Promise((resolve, reject) => {
        this.$emit('open', { component, props, resolve, reject });
      });
    }
  }
})