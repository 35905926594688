import { render, staticRenderFns } from "./ModalBody.vue?vue&type=template&id=999fd29a&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "999fd29a",
  null
  
)

export default component.exports