// -*- coding: utf-8 -*-
// @author: Eric Melcher [em], Created on 08/01/2022
// IMPORTS -------------------------------------------------------------------------------------------------------------
import {api} from './api';

const END_POINT = 'api-keys';

const ALL = '';
const ADD = 'add';
const DELETE = 'delete'
const DELETE_MANY = 'delete-many'


class apiKeysService{

    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    listAllApiKeys() {
        return api.get(`${END_POINT}/${ALL}`)
    }

    addApiKey(data) {
        return api.post(`${END_POINT}/${ADD}`, data)
    }

    deleteApiKey(data) {
        return api.delete(`${END_POINT}/${DELETE}`, data)
    }

    deleteManyApiKeys(data) {
        return api.post(`${END_POINT}/${DELETE_MANY}`, data)
    }

}

export default new apiKeysService(END_POINT)

