<template>
  <div>
    <Navbar v-if="!['Login', 'CreateAccount', 'ResetPassword'].includes($route.name)"></Navbar>
    <!-- <CookieAlert></CookieAlert> -->
    <router-view/>
  </div>
</template>


<script>
import { useGetters, useActions } from 'vuex-composition-helpers';
import { onMounted } from '@vue/composition-api';

import Navbar from './components/Navbar';
// import CookieAlert from './components/CookieAlert'

export default {
  name: 'App',
  components: {
    Navbar,
    // CookieAlert,
  },
  setup(){
    const { isAuthenticated } = useGetters(['isAuthenticated'])
    const { getUserDetails } = useActions(['getUserDetails'])

    const setInitialValues = async () => {

      getUserDetails()

      console.log('[setInitialValues]: Method call');
      if(isAuthenticated.value) {
        console.log('[setInitialValues]: is authenticated.')
      } else {
        console.log('[setInitialValues]: not authenticated.')
      }
    }

    onMounted(setInitialValues)
  }
}



</script>


