<template>
    <b-navbar class="navbar-pg" type="is-dark">
        <template #brand>

            <!-- Logged in-->
            <b-navbar-item tag="a" href="/" type="navbar-brand" v-if="$store.getters.isAuthenticated">
                <img src="../assets/extapose_footer_logo.png"> 
            </b-navbar-item>

            <!-- Logged out -->
            <b-navbar-item tag="a" href="/" type="navbar-brand" v-if="!$store.getters.isAuthenticated">
                <img src="../assets/extapose_footer_logo.png">
            </b-navbar-item>
        </template>
        
        <template #start>
<!--            <b-navbar-dropdown label="Info">-->
<!--                <b-navbar-item href="#">-->
<!--                    About-->
<!--                </b-navbar-item>-->
<!--                <b-navbar-item href="#">-->
<!--                    Contact-->
<!--                </b-navbar-item>-->
<!--            </b-navbar-dropdown>-->
            <b-navbar-item tag="router-link" :to="{ path: '/dashboard' }" v-if="$store.getters.isAuthenticated">
                Dashboard
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/instruments' }" v-if="$store.getters.isAuthenticated">
                Instruments
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/portfolios' }" v-if="$store.getters.isAuthenticated">
                Portfolios
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/modules' }" v-if="$store.getters.isAuthenticated">
                Modules
            </b-navbar-item>

            <b-navbar-item tag="router-link" :to="{ path: '/systems' }" v-if="$store.getters.isAuthenticated">
                Systems
            </b-navbar-item>
        </template>

        <template #end>
            <!-- Logged out-->
            <b-navbar-item tag="div" v-if="!$store.getters.isAuthenticated">
                <b-button tag="router-link" :to="{ path: '/create-account' }"
                    type="navbar-link is-arrowless">
                    Create Account
                </b-button>
            </b-navbar-item>
            <b-navbar-item tag="div" v-if="!$store.getters.isAuthenticated">
                <b-button tag="router-link" :to="{ path: '/login' }"
                    type="navbar-link is-arrowless">
                    Log in
                </b-button>
            </b-navbar-item>

            <!-- Logged in-->
            <b-navbar-item tag="div" v-if="$store.getters.isAuthenticated">
                <b-button tag="router-link" :to="{ path: '/user-profile' }"
                    type="navbar-button">
                    Profile
                </b-button>
            </b-navbar-item>
            <b-navbar-item tag="div" v-if="$store.getters.isAuthenticated">
                <b-button
                    type="navbar-button"
                    class="button is-danger"
                    @click="logout()">
                    Log out
                </b-button>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>



<script>
export default {
  name: "Navbar",
  methods: {
    logout() {
      const vm = this;
      this.$store.dispatch("logout")
      .then(() => {
        vm.$router.push("/");
      });
    }
  },
}
</script>

<style scoped>
  /* a {
    color: #8f3222;
  } */
</style>


<!--<script>-->
// document.addEventListener('DOMContentLoaded', () => {

//   // Get all "navbar-burger" elements
//   const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

//   // Check if there are any navbar burgers
//   if ($navbarBurgers.length > 0) {

//     // Add a click event on each of them
//     $navbarBurgers.forEach( el => {
//       el.addEventListener('click', () => {

//         // Get the target from the "data-target" attribute
//         const target = el.dataset.target;
//         const $target = document.getElementById(target);

//         // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
//         el.classList.toggle('is-active');
//         $target.classList.toggle('is-active');

//       });
//     });
//   }

// });
<!--</script>-->