<!-- @author: Eric Melcher [em], Created on 08/01/2022 -->
<template>
  <div class="TVChartContainer" ref="chartContainer" />
  <!-- This div will contain the Charting Library widget. -->
</template>

<script>

// Datafeed implementation, will be added later
import { widget } from '/public/charting_library';
import Datafeed from './datafeed.js'

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export default {
  name: 'TVChartContainer',
  props: {
    symbol: {
      default: 'kraken-BTC/USD',
      type: String,
    },
    interval: {
      default: '1D',
      type: String,
    },
    datafeedUrl: {
      default: process.env.VUE_APP_FASTAPI_URL,
      type: String,
    },
    // libraryPath: {
    //   default: '/charting_library/',
    //   type: String,
    // },
    chartsStorageUrl: {
      default: 'ericmelcher.ddns.net',
      type: String,
    },
    chartsStorageApiVersion: {
      default: '1.1',
      type: String,
    },
    clientId: {
      default: 'tradingview.com',
      type: String,
    },
    userId: {
      default: 'public_user_id',
      type: String,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    autosize: {
      default: true,
      type: Boolean,
    },
    studiesOverrides: {
      type: Object,
    },
  },
  tvWidget: null,
  mounted() {
    const container = this.$refs.chartContainer;
    const widgetOptions = {
      symbol: this.symbol,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: Datafeed,
      interval: this.interval,
      container: container,
      library_path:
          process.env.NODE_ENV === "production"
              ? "/charting_library/"
              : "/charting_library/",
      locale: getLanguageFromURL() || 'en',
      disabled_features: ['study_templates', 'save_load_adapter', 'header_saveload'],
      enabled_features: [],
      charts_storage_url: this.chartsStorageUrl,
      charts_storage_api_version: this.chartsStorageApiVersion,
      client_id: this.clientId,
      user_id: this.userId,
      fullscreen: this.fullscreen,
      autosize: this.autosize,
      studies_overrides: this.studiesOverrides,
    };
    console.log('[TVChartContainer]:' + ' user id:' + this.userId)
    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;
    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute('title', 'Click to show a notification popup');
        button.classList.add('apply-common-tooltip');
        button.addEventListener('click', () => tvWidget.showNoticeDialog({
            title: 'Notification',
            body: 'TradingView Charting Library API works correctly',
            callback: () => {
              // eslint-disable-next-line no-console
              console.log('Noticed!');
            },
          }));
        button.innerHTML = 'Check API';
      });
    });
    // console.log('[TVChartContainer]:' + this.tvWidget)

  },
  destroyed() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }
}

</script>

<style lang="scss" scoped>
  .TVChartContainer {
    height: calc(100vh - 80px);
  }
</style>