// -*- coding: utf-8 -*-
// @author: Eric Melcher [em], Created on 29/01/2022
// IMPORTS -------------------------------------------------------------------------------------------------------------
// import getters from '@/store/modules/users.store';
import { parseFullSymbol } from './helpers.js';
// import { MarketDataService } from "../../api/market_data";

// console.log('[streaming]' + getters.getters.getProfile())

// eslint-disable-next-line no-undef
const socket = new WebSocket(process.env.VUE_APP_FASTAPI_WS + '/pub/vue-eric'+ (Math.floor(Math.random() * 10) + 1).toString());

const channelToSubscription = new Map();

socket.onopen = function () {
	console.log('[socket] Connected');
};

socket.onclose = function (reason) {
	console.log('[socket] Disconnected:', reason);
};

socket.onerror = function (error) {
	console.log('[socket] Error:', error);
};

socket.onmessage = function (data) {
	// console.log('[socket] Message:', data);

	const msg = JSON.parse(data.data)

	const tradePrice = parseFloat(msg.data);
	const tradeTime = Date.now();
	const channelString = msg.channel;

	console.log('[onmessage] channelString:', channelString)
	console.log('[onmessage] channelToSubscription:', channelToSubscription)

	const subscriptionItem = channelToSubscription.get(channelString);

	// if (subscriptionItem === undefined) {
	//
	// 	return;
	// }
	const lastDailyBar = subscriptionItem.lastDailyBar;
	const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

	console.log('[socket] nextDailyBarTime:', nextDailyBarTime, 'tradeTime:', tradeTime);

	let bar;
	if (tradeTime >= nextDailyBarTime) {
		bar = {
			time: nextDailyBarTime,
			open: tradePrice,
			high: tradePrice,
			low: tradePrice,
			close: tradePrice,
		};
		console.log('[socket] Generate new bar', bar);
	} else {
		bar = {
			...lastDailyBar,
			high: Math.max(lastDailyBar.high, tradePrice),
			low: Math.min(lastDailyBar.low, tradePrice),
			close: tradePrice,
		};
		console.log('[socket] Update the latest bar by price', tradePrice);
	}
	subscriptionItem.lastDailyBar = bar;

	// send data to every subscriber of that symbol
	subscriptionItem.handlers.forEach(handler => handler.callback(bar));
};

function getNextDailyBarTime(barTime) {
	const date = new Date(barTime * 1000);
	date.setDate(date.getDate() + 1);
	return date.getTime() / 1000;
}

export function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscribeUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) {
	const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
	const channelString = `${parsedSymbol.exchange}-${parsedSymbol.fromSymbol}/${parsedSymbol.toSymbol}-trade`;
	const handler = {
		id: subscribeUID,
		callback: onRealtimeCallback,
	};
	let subscriptionItem = channelToSubscription.get(channelString);
	if (subscriptionItem) {
		// already subscribed to the channel, use the existing subscription
		subscriptionItem.handlers.push(handler);
		return;
	}
	subscriptionItem = {
		subscribeUID,
		resolution,
		lastDailyBar,
		handlers: [handler],
	};
	channelToSubscription.set(channelString, subscriptionItem);
	console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
	socket.send(JSON.stringify(
		{
				action: "subscribe",
				sub_type: "trade",
				instrument_id: symbolInfo.instrument_id
		}
	));
}

export function unsubscribeFromStream(subscriberUID) {
	// find a subscription with id === subscriberUID
	for (const channelString of channelToSubscription.keys()) {
		const subscriptionItem = channelToSubscription.get(channelString);
		const handlerIndex = subscriptionItem.handlers
			.findIndex(handler => handler.id === subscriberUID);

		if (handlerIndex !== -1) {
			// remove from handlers
			subscriptionItem.handlers.splice(handlerIndex, 1);

			if (subscriptionItem.handlers.length === 0) {

				// unsubscribe from the channel, if it was the last handler
				console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString);
				socket.send(JSON.stringify(
					{
							action: "unsubscribe",
							sub_type: "trade",
							instrument_id: channelString
					}
				));
				channelToSubscription.delete(channelString);
				break;

			}
		}
	}
}