// -*- coding: utf-8 -*-
// @author: Eric Melcher [em], Created on 29/01/2022
// IMPORTS -------------------------------------------------------------------------------------------------------------


// boilerplate ---------------------------------------------------------------------------------------------------------
import {subscribeOnStream, unsubscribeFromStream,} from './streaming.js';
import {InstrumentsService} from '../../api/instruments'
import {MarketDataService} from '../../api/market_data'

const lastBarsCache = new Map();

const configurationData = {
	supported_resolutions: ['1', '1h', '1D'], 		//, '1W', '1M'], currently only supports the EOD tables
	exchanges: [
		{
			// `exchange` argument for the `searchSymbols` method, if a user selects this exchange
			value: 'kraken',
			// filter name
			name: 'Kraken',
			// full exchange name displayed in the filter popup
			desc: 'Kraken crypto exchange',
		},
		{
			value: 'krakenfutures',
			name: 'KrakenFutures',
			desc: 'Kraken derivatives exchange',
		},
		{
			value: 'avanza',
			name: 'Avanza',
			desc: 'Avanza',
		},
	],
	symbols_types: [
		{
			name: 'Crypto',
			// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
			value: 'crypto',
		},
		{
			name: 'Stocks',
			// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
			value: 'stock',
		},
		{
			name: 'Futures',
			// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
			value: 'future',
		},
	],
};

async function getAllSymbols() {
	// Get all instruments and convert to "TV-symbols"
	console.log('[getAllSymbols]: Method call');
	const response = await InstrumentsService.getAllInstrumentsQuery(
		{
			exchange_broker: {'$in': ['kraken', 'krakenfutures', 'avanza']},
			type: {'$in': ['crypto', 'stock', 'future']}
		}
	);
	let arrayObj = response.data
	return arrayObj.map(({ticker, exchange_broker, _id, type}) => ({
		symbol: ticker,
		full_name: _id,
		description: 'None_str',
		exchange: exchange_broker,
		type: type,
	}));
}


export default {
	onReady: (callback) => {
		console.log('[onReady]: Method call');
		setTimeout(() => callback(configurationData));
	},

	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback,
	) => {
		console.log('[searchSymbols]: Method call');
		const symbols = await getAllSymbols();
		// print all symbols retrieved
		// console.log(symbols)
		const newSymbols = symbols.filter(symbol => {
			const isExchangeValid = exchange === '' || symbol.exchange === exchange;
			const isFullSymbolContainsInput = symbol.full_name
				.toLowerCase()
				.indexOf(userInput.toLowerCase()) !== -1;
			return isExchangeValid && isFullSymbolContainsInput;
		});
		onResultReadyCallback(newSymbols);
	},

	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
	) => {
		console.log('[resolveSymbol]: Method call', symbolName);
		const response = await InstrumentsService.getInstrument({
			id: symbolName
		});

		const instrumentObj = response.data

		console.log('[resolveSymbol]: response: ' + JSON.stringify(instrumentObj))

		if (instrumentObj === null) {
			console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
			onResolveErrorCallback('cannot resolve symbol');
			return;
		}

		const symbolInfo = {
			instrument_id: instrumentObj._id,
			ticker: instrumentObj._id,
			name: instrumentObj.name,
			description: instrumentObj.description,
			type: instrumentObj.type,
			session: '24x7',
			timezone: 'Etc/UTC',
			exchange: instrumentObj.exchange_broker,
			minmov: 1,
			pricescale: 100,
			has_intraday: true,
			has_no_volume: true,
			has_weekly_and_monthly: false,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: 'streaming',
		};

		console.log('[resolveSymbol]: Symbol resolved', symbolName);
		onSymbolResolvedCallback(symbolInfo);
	},

	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const { from, to, firstDataRequest } = periodParams;
		console.log('[getBars]: Method call', symbolInfo, resolution+'(1min)', from, to);

		// Always request 1min resolution, tv resamples to higher resolutions
		try {
			const response = await MarketDataService.getOHLC({
				instrument_id: symbolInfo.instrument_id,
				resolution: '1min',
				from_ts: parseFloat(from),
				to_ts: parseFloat(to)
			});

			const bars = response.data
			console.log('[getBars]: bars list (' + response.data.length + '):', JSON.stringify(response.data[0]))


			if (response.data.error || bars.length === 0) {
				// "noData" should be set if there is no data in the requested period.
				console.log('[getBars]: no data in bars ls')
				onHistoryCallback([], {
					noData: true,
				});
				return;
			}

			if (firstDataRequest) {
				console.log(`[getBars]: first data request`, bars[bars.length - 1]);
				lastBarsCache.set(symbolInfo.instrument_id + '-trade', {
					...bars[bars.length - 1],
				});
			}

			console.log(`[getBars]: returned ${bars.length} bar(s)`);
			// console.log(bars)
			onHistoryCallback(bars, {
				noData: false,
			});

		} catch (error) {
			console.log('[getBars]: Get error', error);
			onErrorCallback(error);
		}

	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscribeUID,
		onResetCacheNeededCallback,
	) => {
		console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscribeUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.instrument_id+'-trade'),
		);
	},

	unsubscribeBars: (subscriberUID) => {
		console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		unsubscribeFromStream(subscriberUID);
	},
};