// -*- coding: utf-8 -*-
// @author: Eric Melcher [em], Created on 17/10/2021
// IMPORTS -------------------------------------------------------------------------------------------------------------
import {fast} from './api';

const END_POINT = 'market-data';
const TRADES = 'trades';
const OHLC = 'ohlc';

const EOD = 'eod';
const ORDERBOOK = 'orderbook';

const FAST_WS = process.env.VUE_APP_FASTAPI_WS;


export class MarketDataService{

    static getTrades(data) {
        // Trades tick data
        console.log('[getTrades]: -> ', JSON.stringify(data))
        return fast.post(`${END_POINT}/${TRADES}`, data)
    }

    static getOHLC(data) {
        // OHLC bar data
        console.log('[getOHLC]: -> ', JSON.stringify(data))
        return fast.post(`${END_POINT}/${OHLC}`, data)
    }

    static wsTrades() {
        return new WebSocket( `${FAST_WS}/${TRADES}`)
    }

    static getEod(data) {
        // End of day data
        console.log('[getEod]: -> ', JSON.stringify(data))
        return fast.post(`${END_POINT}/${EOD}`, data)
    }

    static ws() {

        return new WebSocket( `${FAST_WS}`)
    }

    static getOrderbook(data, _id) {
        // Orderbook tick data
        console.log('[getOrderbook]: -> ', JSON.stringify(data))
        return fast.get(`${END_POINT}/${ORDERBOOK}/${_id}`, data)
    }

    static wsOrderbook() {

        return new WebSocket( `${FAST_WS}/${ORDERBOOK}`)
    }

}
