// -*- coding: utf-8 -*-
// @author: Eric Melcher [em], Created on 17/10/2021
// IMPORTS -------------------------------------------------------------------------------------------------------------
import {fast} from './api';

const END_POINT = 'instruments';
const SEARCH = 'search';
const ALL = 'all';
const ALLQUERY = 'all-query';

export class InstrumentsService{

    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    static getAllInstruments(data) {
        return fast.get(`${END_POINT}/${ALL}`, data)
    }

    static getAllInstrumentsQuery(data) {
        console.log('[getAllInstrumentsQuery]: -> ', JSON.stringify(data))
        return fast.post(`${END_POINT}/${ALLQUERY}`, data)
    }

    static getInstrument(data) {
        console.log('[getInstrument]: -> ', JSON.stringify(data))
        return fast.post(`${END_POINT}/`, data)
    }

    searchInstrument(data, freetext) {
        return fast.post(`${END_POINT}/${SEARCH}?${freetext}`, data)
    }

}

export default new InstrumentsService(END_POINT)
