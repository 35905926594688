<!-- @author: Eric Melcher [em], Created on 08/01/2022 -->
<template>
  <section :class="{ 'modal-root': modal.component }">
    <transition name="modal">
      <component
        v-if="modal"
        :is="modal.component"
        v-bind:close="modal.close"
        v-bind:dismiss="modal.dismiss"
        v-bind="modal.props"
        :class="{ 'd-block': modal.component }"
      />
    </transition>
  </section>
</template>

<script>
import ModalService from './modal.service';
import Modal from './Modal.vue';
export default {
  components: { Modal },
  data() {
    return {
      modal: {},
    }
  },
  created() {
    ModalService.$on('open', ({ component, props, resolve, reject }) => {
      this.modal = {
        component,
        props,
        close: value => {
          this.modal = {};
          resolve(value);
        },
        dismiss: reason => {
          this.modal = {};
          reject(reason);
        },
      };
    });
  }
}
</script>

<style scoped>

</style>