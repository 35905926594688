import Vue from 'vue'
import VueRouter from 'vue-router'
import VueBodyClass from 'vue-body-class';

import routes from "./routes/index";
import { store } from '@/store'

Vue.use(VueRouter)

let router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  mode: 'history',
  linkActiveClass: 'is-active',
  routes 
});


router.beforeEach((to, from, next) => {
  if (to.meta.authorize) {
    if (store.getters.isAuthenticated) {
      next()
    } else if (to.name != 'Login') {
      next({name: 'Login'})
    } else {
      next()
    }
  } else {
    if (store.getters.isAuthenticated && to.name == 'Login') {
      next({name: 'UserProfile'})
    } else {
      next()
    }
  }
})

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });

export default router;