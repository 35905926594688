
<template>
  <div class="login-pg">
    <div class="login-holder">
      <div class="login-header">
        <img src="../assets/extapose_logo.png" alt="Extapose Logo">
      </div>
      <div class="login-content">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <!-- the "handleSubmit" function on the slot-scope executes the callback if validation was successfull -->
          <section class="section">
            <BInputWithValidation
              rules="required|email"
              type="email"
              class="mb-12 d-block"
              vid="email"
              label="Email"
              v-model="email"
              :placeholder="'Username'"
            />
            <BInputWithValidation
              rules="required"
              type="password"
              label="Password"
              vid="password"
              class="mb-12 d-block"
              v-model="password"
              :placeholder="'Password'"
              @keyup.enter.native="handleSubmit(submit)"
            />
            <div class="buttons is-centered mb-6" @keyup.enter="handleSubmit(submit)">
              <b-button
                type="login-button"
                @click="handleSubmit(submit)">
                {{ $t('auth.login') }}
              </b-button>
            </div>
            <div class="columns is-mobile">
              <div class="column">
                <div class="checkbox mt-16">
                  <input type="checkbox" id="remember_username">
                  <label for="remember_username">Remember me</label>
                </div>
              </div>
              <div class="column mt-16 has-text-right">
                <router-link :to="{ path: '/reset-password' }">Forgot password?</router-link>
              </div>
            </div>
          </section>
        </ValidationObserver>
      </div>
      <div class="login-footer">
        <img src="../assets/extapose_footer_logo.png" alt="Extapose Logo">
      </div>
    </div>
    <div class="login-footer-menu">
      <ul>
        <li><a href="#">About extapose</a></li>
        <li><a href="#">About cookies</a></li>
        <li class="dropup"><a href="#">English <span class="caret caret-sm"></span></a></li>
      </ul>
    </div>
  </div>
</template>


<script>
  import { ref } from '@vue/composition-api';
  import { useActions, useGetters } from 'vuex-composition-helpers';

  export default {

    name: "LoginForm",

    setup(props, { root }){
      const form = ref(null)
      const email = ref(null)
      const password = ref(null)
      const { login } = useActions(['login'])
      const { isAuthenticated } = useGetters(['isAuthenticated'])

      const submit = async() => {
        console.log("trying to sumbit")
        try {
          await login({email: email.value, password: password.value})
        } catch (err){
          console.log("sumbit resulted in error")
          form.value.setErrors({email: 'Invalid email', password: 'Invalid password'})
        } finally {
          if(isAuthenticated.value) {
            root.$router.push({name: 'Home'})
          }
        }
      }
      return {
        form,
        email,
        password,
        submit
      }
    }
  }
</script>

<style scoped>

</style>