/* eslint-disable no-unused-vars */

import {GET_IP} from "./auth.store";
import UsersService from "@/api/users.js";

const UPDATE_DETAILS = "UPDATE_DETAILS";

const consts = {
  BDC_CHOICES: [
    {
      value: 'UNADJUSTED',
      label: 'Unadjusted'
    },
    {
      value: 'MODIFIEDFOLLOWING',
      label: 'Modified Following'
    },
    {
      value: 'FOLLOWING',
      label: 'Following'
    },
    {
      value: 'PRECEDING',
      label: 'Preceding'
    },
    {
      value: 'MODIFIEDPRECEDING',
      label: 'Modified Preceding'
    }
  ],
  DCC_CHOICES: [
    {
      value: '30_360',
      label: '30/360 (360/360)'
    },
    {
      value: '30E_360',
      label: '30E/360'
    },
    {
      value: '30E_360_ISDA',
      label: '30E/360 (ISDA)'
    },
    {
      value: 'ACTUAL_360',
      label: 'Actual/360'
    },
    {
      value: 'ACTUAL_365_FIXED',
      label: 'Act/365F'
    },
    {
      value: 'ACTUAL_ACTUAL_ISDA',
      label: 'Act/365L (ISDA)'
    }
  ],
  FREQUENCY_CHOICES: [
    {
      value: '1M',
      label: '1M'
    },
    {
      value: '2M',
      label: '2M'
    },
    {
      value: '3M',
      label: '3M'
    },
    {
      value: '4M',
      label: '4M'
    },
    {
      value: '6M',
      label: '6M'
    },
    {
      value: '12M',
      label: '12M'
    },
   ],
   RATE_METHODS: [
     {
       value: 'FIXED',
       label: 'Fixed'
     },
     {
       value: 'FLOATING',
       label: 'Floating'
     }
   ],
   REFERENCE_RATES: [
    {
      value: 'STIBOR1M',
      label: 'STIBOR1M'
    },
    {
      value: 'STIBOR2M',
      label: 'STIBOR2M'
    },
    {
      value: 'STIBOR3M',
      label: 'STIBOR3M'
    }
   ]
}

const state = {
  business_day_conventions: consts.BDC_CHOICES,
  countries: ['SE', 'DK', 'NO'],
  currencies: ['SEK', 'DKK', 'NOK'],
  day_count_conventions: consts.DCC_CHOICES,
  default_country: "SE",
  default_currency: "SEK",
  frequencies: consts.FREQUENCY_CHOICES,
  rate_methods: consts.RATE_METHODS,
  reference_rates: consts.REFERENCE_RATES,
  status: "",

  // The logged in users details, email, name, address etc.
  details: {},
  details_ls: []
};

const getters = {
  business_day_conventions: (s) => s.business_day_conventions,
  countries: (s) => s.countries,
  currencies: (s) => s.currencies,
  default_country: (s) => s.default_country,
  default_currency: (s) => s.default_currency,
  day_count_conventions: (s) => s.day_count_conventions,
  frequencies: (s) => s.frequencies,
  rate_methods: (s) => s.rate_methods,
  reference_rates: (s) => s.reference_rates,

  // User details, email, name etc...
  details: (s) => s.details,
  details_ls: (s) => s.details_ls

};

const actions = {

  async createUser({ commit, dispatch }, { email, password, first_name, last_name }) {
    console.log(first_name)
    try {
      let resp = await UsersService.createUser({ email, password, first_name, last_name })
      dispatch(GET_IP).then()
      return resp
    } catch (error){
      console.log(error)
    }
  },

  async getUserDetails({ commit, dispatch }) {
    try {
      let resp = await UsersService.getUserDetails()
      dispatch(GET_IP).then()
      commit(UPDATE_DETAILS, resp.data)
      return resp
    } catch (error){
      console.log(error)
    }
  },

  async updateUserPassword({ commit, dispatch }, { current_password, new_password, confirm_new_password }) {
    try {
      let resp = await UsersService.updateUserPassword({ current_password, new_password, confirm_new_password })
      dispatch(GET_IP).then()
      return resp
    } catch (error){
      console.log(error)
    }
  },

  async resetUserPassword({ commit, dispatch }, { email }) {
    try {
      let resp = await UsersService.resetUserPassword({ email })
      dispatch(GET_IP).then()
      return resp
    } catch (error){
      console.log(error)
    }
  },
};

const mutations = {
  [UPDATE_DETAILS]: (state, data) => {
    state.details_ls = []
    Object.entries(data).forEach(([key, val]) => {
      state.details[key] = val
      state.details_ls.push({[key]: val})
    })
    // console.log(state.details_ls)
  },
};

export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations
};
