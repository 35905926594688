<template>
  <div>
    <TVChartContainer />
  </div>
</template>

<script>
import TVChartContainer from '../components/TVcharts/TVChartContainer.vue'

export default {
  name: 'Dashboard',
  components: {
    TVChartContainer,
  },

  data() {
    return {

    }
  },

  methods: {
  },

  mounted() {
  },

}
</script>

<style scoped>
</style>