<template>
  <Modal>
    <ModalHeader>
      <h3>Add API key</h3>
    </ModalHeader>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <ModalBody>
        <form action="">
          <div class="modal-card" style="width: auto">
            <b-field label="Key name" class="required">
              <b-input
                type="name"
                placeholder="Give the key a name for easier identification"
                v-model="name"
                required>
              </b-input>
            </b-field>
            <b-field label="Exchange or Broker" class="required">
              <b-input
                type="name"
                placeholder="Exchange (e.g. Kraken)"
                v-model="exchange_broker"
                required>
              </b-input>
            </b-field>
            <b-field label="API key" class="required">
              <b-input
                type="password"
                placeholder="Public key"
                v-model="pub_key"
                password-reveal
                required>
              </b-input>
              <b-input
                type="password"
                placeholder="Private key"
                v-model="priv_key"
                password-reveal
                required>
              </b-input>
            </b-field>
          </div>
        </form>
      </ModalBody>

      <ModalFooter>
        <b-button
          @click="handleSubmit(submit)"
          class="button is-primary"
          :disabled="!name"
        >Add</b-button>
        <button
            @click="close"
            class="button"
        >Cancel</button>
      </ModalFooter>

    </ValidationObserver>
  </Modal>
</template>

<script>

import Modal from '../Modals/Modal.vue';
import ModalHeader from '../Modals/ModalHeader.vue';
import ModalFooter from '../Modals/ModalFooter.vue';
import ModalBody from '../Modals/ModalBody.vue';
import ModalMixin from '../Modals/ModalMixin';
import {ref} from "@vue/composition-api";
import {useActions} from "vuex-composition-helpers";

export default {

  components: { Modal, ModalHeader, ModalBody, ModalFooter },
  mixins: [ModalMixin],

  setup() {
    const form = ref(null)
    const name = ref(null)
    const exchange_broker = ref(null)
    const pub_key = ref(null)
    const priv_key = ref(null)

    const { addApiKey } = useActions(['addApiKey'])

    const submit = async() => {
        console.log("trying to sumbit")
      try {
        const data = {
          name: name.value,
          exchange_broker: exchange_broker.value,
          pub_key: pub_key.value,
          priv_key: priv_key.value,
        }
        console.log(data)
        await addApiKey(data, data)
        } catch (err){
          console.log("sumbit resulted in error")
          console.log(err)
        } finally {
          console.log()

        }
      }
    return {
      form,
      name,
      exchange_broker,
      pub_key,
      priv_key,
      submit
    }
  }
}

</script>