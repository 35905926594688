import axios from "axios";
import Vue from 'vue'
// import Vuex from 'vuex'
import { store } from '@/store'
import router from '../router/router.js'

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 15000,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json"
    }
});

const fast = axios.create({
    baseURL: process.env.VUE_APP_FASTAPI_URL,
    timeout: 35000,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json"
    }
});


// const fastws = axios.create({
//     baseURL: process.env.VUE_APP_FASTAPI_WS,
//     timeout: 15000,
//     withCredentials: true,
//     headers: {
//         "Content-Type": "application/json"
//     }
// });

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 1) return parts.pop().split(";").pop();
}

api.interceptors.request.use(
  (config) => {

    const c = config;

    const cookie = getCookie("csrftoken") || "";

    if (cookie) {
      c.headers["X-CSRFToken"] = cookie;
    }

    return c;
  },
  (error) => {
    Promise.reject(error);
  }
);


function handleSuccess(response) {
  return { data: response.data };
}

function handleError(error) {
    console.log(error.response)
  switch (error.response.status) {

    case 400:
      break;

    case 401:
      // Log out user, remove token, clear state and redirect to login
      if(store.getters.isAuthenticated){
        store.dispatch("logout").then( () => {
          router.push({name: "Login"})
        });
      }
      break;

    case 404:
      // Show 404 page
      break;

    case 500:
      // Serveur Error redirect to 500
      break;

    default:
      // Unknow Error
      break;

  }
  return Promise.reject(error);
}

api.interceptors.response.use(handleSuccess, handleError);
fast.interceptors.response.use(handleSuccess, handleError);


Vue.prototype.$api = api;
Vue.prototype.$fast = fast;
// Vue.prototype.$fastws = fastws;


export {
    api,
    fast,
    //fastws
}

