// -*- coding: utf-8 -*-
// @author: Eric Melcher [em], Created on 08/01/2022
// IMPORTS -------------------------------------------------------------------------------------------------------------
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-catch */
import PortfoliosService from '@/api/portfolios.js';

const UPDATE_PORTFOLIO_LIST = 'UPDATE_PORTFOLIO_LIST';
const UPDATE_CHECKED_PORTFOLIOS_LIST = 'UPDATE_CHECKED_PORTFOLIOS_LIST';

export default {
  // namespaced: true,

  state: {
    portfolios_ls: [],
    portfolios_obj: {},
    checked_portfolios_ls: [],
  },

  getters: {
    getAllPortfolios(state) {
      return state.portfolios_ls
    }
  },

  mutations: {
    [UPDATE_PORTFOLIO_LIST](state, data) {
      state.portfolios_ls = data
      state.portfolios_obj = data.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
    },
  },

  actions: {
    async listAllPortfolios({ commit, dispatch }) {
      try{
        let response = await PortfoliosService.listAllPortfolios()
        console.log(response.data)
        commit(UPDATE_PORTFOLIO_LIST, response.data)
        return response
      }catch (error){
        throw(error)
      }
    },
  },
};