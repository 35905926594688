import {api} from './api';

const END_POINT = 'auth';
const LOGIN = 'login';
const LOGOUT = 'logout';
const SET_COOKIE = 'login-set-cookie';

class AuthService{

    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    getLoginToken(){
      return api.get(`${END_POINT}/${SET_COOKIE}`)
    }

    login(data){

        console.log(data)
        return api.post(`${END_POINT}/${LOGIN}`, data)
    }
    
    logout(){
      api.post(`${END_POINT}/${LOGOUT}`)
    }

}

export default new AuthService(END_POINT)