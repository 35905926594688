<template>
<div id="modules" class="container">  
  <section class="module-section">
    <template>
        <div class="is-flex is-justify-content-flex-end">
            <b-dropdown aria-role="list" class="filter-dropdown">
                <template #trigger="{ active }">
                    <b-button
                        label="Filter columns"
                        class="table-filer"
                        :icon-right="active ? 'menu-up' : 'menu-down'" />
                </template>
                
                <b-field grouped group-multiline>
                    <div v-for="(column, index) in columnsVisible"
                        :key="index"
                        class="control">
                        <b-checkbox v-model="column.display">
                            {{ column.title }}
                        </b-checkbox>
                    </div>
                </b-field>
            </b-dropdown>
        </div>
    </template>


    <b-table
        :data="data"
        :striped="isStriped"
        :paginated="isPaginated"
        :per-page="perPage"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-simple="isPaginationSimple"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        >



        <b-table-column
                field="tenor"
                :visible="columnsVisible['tenor'].display"
                :label="columnsVisible['tenor'].title"
                v-slot="props"
                sortable="sortable"
                subheading="<input type='text'>"
            >
                {{ props.row.tenor }}
            </b-table-column>        
            <b-table-column
                field="exchange"
                :visible="columnsVisible['exchange'].display"
                :label="columnsVisible['exchange'].title"
                v-slot="props" 
                subheading="Test"
                filterOptions= "{enabled: true, placeholder: 'Filter Name', filterValue: '', filterDropdownItems: [], filterFn: this.columnFilterFn, trigger: 'enter'}"
            >
                {{ props.row.exchange }}
            </b-table-column>        
            <b-table-column
                field="time"
                :visible="columnsVisible['time'].display"
                :label="columnsVisible['time'].title"
                v-slot="props"
            >
                {{ props.row.time }}
            </b-table-column>       
            <b-table-column
                field="rate"
                :visible="columnsVisible['rate'].display"
                :label="columnsVisible['rate'].title"
                v-slot="props"
            >
                {{ props.row.rate }}
            </b-table-column>       
            <b-table-column
                field="volume"
                :visible="columnsVisible['volume'].display"
                :label="columnsVisible['volume'].title"
                v-slot="props"
                sortable="sortable"
            >
                {{ props.row.volume }}
            </b-table-column>    
            <b-table-column
                field="interest"
                :visible="columnsVisible['interest'].display"
                :label="columnsVisible['interest'].title"
                v-slot="props"
            >
                {{ props.row.interest }}
            </b-table-column>

        <template #bottom-left>
            <b-button
                label="Save"
                type="is-primary"
                class="mr-8"
                />          
            <b-button
                label="Cancel"
                />
        </template>
    </b-table>
  </section>

  
</div>
</template>



<script>
export default {
        data() {
            return {
                data: [
                    { 'tenor': 'BTC/USD Prep', 'exchange': 'Kraken', 'time': '1d 23m', 'rate': '12%', 'volume': '11.234', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD 1M', 'exchange': 'Kraken', 'time': '2d 10m', 'rate': '11%', 'volume': '2.345', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD 3M', 'exchange': 'Kraken', 'time': '3d', 'rate': '5%', 'volume': '3.233', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD 6M', 'exchange': 'Kraken', 'time': '12d', 'rate': '7%', 'volume': '13.221', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'ETH/USD Prep', 'exchange': 'Kraken', 'time': '14d', 'rate': '10%', 'volume': '56.334', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD Prep', 'exchange': 'Coinbase', 'time': '30d', 'rate': '1%', 'volume': '43.234', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD Prep 2', 'exchange': 'Coinbase', 'time': '32d', 'rate': '14%', 'volume': '54.742', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD Prep 2', 'exchange': 'Coinbase', 'time': '32d', 'rate': '14%', 'volume': '54.742', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD Prep 2', 'exchange': 'Coinbase', 'time': '32d', 'rate': '14%', 'volume': '54.742', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD Prep 2', 'exchange': 'Coinbase', 'time': '32d', 'rate': '14%', 'volume': '54.742', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD Prep 2', 'exchange': 'Coinbase', 'time': '32d', 'rate': '14%', 'volume': '54.742', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD Prep 2', 'exchange': 'Coinbase', 'time': '32d', 'rate': '14%', 'volume': '54.742', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD Prep 2', 'exchange': 'Coinbase', 'time': '32d', 'rate': '14%', 'volume': '54.742', 'interest': 'Lorem ipsum' },
                    { 'tenor': 'BTC/USD Prep 3', 'exchange': 'Coinbase', 'time': '45d', 'rate': '12%', 'volume': '21.745', 'interest': 'Lorem ipsum' }
                ],
                
                columnsVisible: {
                    tenor: { title: 'Pair by tenor', display: true },
                    exchange: { title: 'Exchange', display: true },
                    time: { title: 'Time to exp', display: true },
                    rate: { title: 'Rate (ann.)', display: true },
                    volume: { title: 'Volume', display: true },
                    interest: { title: 'Open interest', display: true },
                },

                isBordered: false,
                isStriped: true,
                isHoverable: true,
                isPaginated: true,
                perPage: 6,
                paginationPosition: 'bottom',
                isPaginationSimple: true,
                defaultSortDirection: 'asc',
                sortIcon: 'arrow-up',
                sortIconSize: 'is-small'
            }
            
        },

        methods: {
            toggle(row) {
                this.$refs.table.toggleDetails(row)
            }

            
        }

 
    }
</script>

<style scoped>
</style>
