
<template>
  <div class="login-pg">
    <div class="login-holder">
      <div class="login-header">
        <img src="../assets/extapose_logo.png" alt="Extapose Logo">
      </div>
      <div class="login-content">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <!-- the "handleSubmit" function on the slot-scope executes the callback if validation was successfull -->
          <section class="section">
            <BInputWithValidation
              rules="required|email"
              type="email"
              class="mb-12 d-block"
              vid="email"
              label="Email"
              v-model="email"
              :placeholder="'Email'"

            />
            <BInputWithValidation
              rules="required"
              type="name"
              class="mb-12 d-block"
              vid="name"
              label="First name"
              v-model="firstName"
              :placeholder="'First name'"
            />
            <BInputWithValidation
              rules="required"
              type="name"
              class="mb-12 d-block"
              vid="name"
              label="Last name"
              v-model="lastName"
              :placeholder="'Last name'"
            />
            <BInputWithValidation
              rules="required"
              type="password"
              class="mb-12 d-block"
              label="Password"
              vid="password"
              v-model="password"
              :placeholder="'Password'"
            />
            <div class="buttons is-centered mb-6">
              <b-button
                type="login-button"
                @click="handleSubmit(submit)">
                Create account
              </b-button>
            </div>
          </section>
        </ValidationObserver>
      </div>
      <div class="login-footer">
        <img src="../assets/extapose_footer_logo.png" alt="Extapose Logo">
      </div>
    </div>
    <div class="login-footer-menu">
      <ul>
        <li><a href="#">About eXtapose</a></li>
        <li><a href="#">About cookies</a></li>
        <li class="dropup"><a href="#">English <span class="caret caret-sm"></span></a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default {

  name: "CreateUserForm",

  setup(props, { root }){
    const form = ref(null)
    const email = ref(null)
    const title = ref(null)
    const firstName = ref(null)
    const lastName = ref(null)

    const password = ref(null)
    const { createUser } = useActions(['createUser'])

    const submit = async() => {
      console.log("trying to sumbit")
      try {
        const data = {
          email: email.value,
          password: password.value,
          first_name: firstName.value,
          last_name: lastName.value
        }
        console.log(data)
        await createUser(data, data)
      } catch (err){
        console.log("sumbit resulted in error")
        console.log(err)
      } finally {
          root.$router.push({name: 'Home'})
      }
    }
    return {
      form,
      email,
      title,
      firstName,
      lastName,
      password,
      submit
    }
  }
}

</script>

