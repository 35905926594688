import Vuex from 'vuex'
import Vue from 'vue'

import auth from '@/store/modules/auth.store';
import user from '@/store/modules/users.store';
import apiKeys from '@/store/modules/apiKeys.store';
import instruments from '@/store/modules/instruments.store';
import portfolios from '@/store/modules/portfolios.store';
import default_ from '@/store/modules/default.store';

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    auth,
    user,
    apiKeys,
    instruments,
    portfolios,
    default_,
  }
})

Vue.prototype.$store = store;
