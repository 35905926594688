<!-- @author: Eric Melcher [em], Created on 08/01/2022 -->
<template>
  <div>
    <table>
      <tr>
        <td>TradingView Charting Lib, Vue.js version</td>
        <td> {{ tv_version }}</td>
      </tr>
      <tr>
        <td>Django-Ninja base URL</td>
        <td>{{ ninja_url }}</td>
      </tr>
      <tr>
        <td>FastAPI base URL</td>
        <td>{{ fast_url }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import {version} from "../../public/charting_library";

export default {

  name: "Systems",

  data() {
    return {
      tv_version: version(),
      ninja_url: process.env.VUE_APP_API_URL,
      fast_url: process.env.VUE_APP_FASTAPI_URL,
    }
  },

  mounted() {

    console.log("Connecting to test-heartbeat websocket")
    try {
      this.connection = new WebSocket(process.env.VUE_APP_FASTAPI_WS + "/pub/vue-eric")
      this.connection.onopen = function (event) {
        console.log(event)
        console.log("Connected sccessfully")
      }
      this.connection.onmessage = function(event) {
        console.log(event.data)
      }
    } catch (err) {
      console.log('connect to ws error:')
      console.log(err)
    }
  }

}
</script>

<style scoped>
</style>