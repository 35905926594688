import { render, staticRenderFns } from "./ModalFooter.vue?vue&type=template&id=849b108c&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "849b108c",
  null
  
)

export default component.exports