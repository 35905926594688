
<template>
  <div class="login-pg">
    <div class="login-holder">
      <div class="login-header">
        <img src="../assets/extapose_logo.png" alt="Extapose Logo">
      </div>
      <div class="login-content">
          <!-- the "handleSubmit" function on the slot-scope executes the callback if validation was successfull -->
          <section class="section">
            <h3 class="mb-12 d-block">Account recovery</h3>
            <BInputWithValidation
              rules="required|email"
              type="email"
              class="mb-12 d-block"
              vid="email"
              label="Email"
              v-model="email"
              :placeholder="'Email'"
            />
            <div class="buttons is-centered mb-6">
              <button
                class="button is-primary"
                @click="resetUserPassword({email: email})">
                Reset password
              </button>
            </div>
          </section>
      </div>
      <div class="login-footer">
        <img src="../assets/extapose_footer_logo.png" alt="Extapose Logo">
      </div>
    </div>
    <div class="login-footer-menu">
      <ul>
        <li><a href="#">About eXtapose</a></li>
        <li><a href="#">About cookies</a></li>
        <li class="dropup"><a href="#">English <span class="caret caret-sm"></span></a></li>
      </ul>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";

export default {

  name: "ResetPassword",

  methods: {
    ...mapActions(['resetUserPassword']),
  },

  data() {
    return {
      email: ''
    }
  }
}
</script>

<style scoped>

</style>