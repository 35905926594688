<!-- @author: Eric Melcher [em], Created on 08/01/2022 -->
<template>
  <table>
    <tr>
      <td>name</td>
      <td>{{ first_name }} {{ details.last_name }}</td>
    </tr>
    <tr>
      <td>email</td>
      <td>{{ details.email }}</td>
    </tr>
    <tr>
      <td>institution</td>
      <td>{{ details.institution }}</td>
    </tr>
    <tr>
      <td>groups</td>
      <td>{{ details.groups }}</td>
    </tr>
    <tr>
      <td>title</td>
      <td>{{ details.title }}</td>
    </tr>
  </table>
</template>

<script>

// import { useActions } from 'vuex-composition-helpers';
import {mapGetters} from "vuex";

export default {

  computed: {
    ...mapGetters(['details']),
  },

  setup() {
  },

}
</script>