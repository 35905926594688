<template>
  <section>
        <b-tabs 
          :position="atRight ? 'is-right' : ''"
          :size="size"
          :type="type"
          vertical
          :animated=false
          :expanded="expanded">

          <b-tab-item label="General" headerClass="general no-icon">
              <section class="section">
                <h1>Styling</h1>
                <h3 class="mb-10">Frameworks we use:</h3>
                <ul>
                  <li><a class="has-text-weight-bold" href="https://bulma.io/documentation/">Bulma</a> - styling, helper classes, components, layout and grid</li>
                  <li><a class="has-text-weight-bold" href="https://buefy.org/">Buefy</a> - UI components for Vue based on Bulma</li>
                </ul>
              </section>
          </b-tab-item>


          <b-tab-item label="Helper classes" headerClass="helper-classes no-icon">
            <section class="section">
              <h1>Helper classes</h1>
              <h3 class="mb-10">Display</h3>
              <ul>
                <li><code>.d-none</code> or <code>.d-{sm,md,lg,xl}-none</code></li>
                <li><code>.d-inline</code> or <code>.d-{sm,md,lg,xl}-inline</code></li>
                <li><code>.d-inline-block</code> or <code>.d-{sm,md,lg,xl}-inline-block</code></li>
                <li><code>.d-block</code> or <code>.d-{sm,md,lg,xl}-block</code></li>
                <li><code>.d-flex</code> or <code>.d-{sm,md,lg,xl}-flex</code></li>
                <li><code>.d-inline-flex</code> or <code>.d-{sm,md,lg,xl}-inline-flex</code></li>
              </ul>

              <hr>

              <h3 class="mb-10">Spacings - margins and paddings</h3>
              <p class="mb-10">The classes are named using the format <code>{property}{sides}-{size}</code> and <code>{property}{sides}-{breakpoint}-{size}</code></p>
              <p><code>{property}</code> is one of:</p>
              <ul class="mb-10">
                <li><code>m</code> - for classes that set margin</li>
                <li><code>p</code> - for classes that set padding</li>
              </ul>
              <p><code>{sides}</code> is one of:</p>
              <ul class="mb-10">
                <li><code>t</code> - for classes that set margin-top or padding-top</li>
                <li><code>b</code> - for classes that set margin-bottom or padding-bottom</li>
                <li><code>l</code> - for classes that set margin-left or padding-left</li>
                <li><code>r</code> - for classes that set margin-right or padding-right</li>
                <li><code>x</code> - for classes that set both *-left and *-right</li>
                <li><code>y</code> - for classes that set both *-top and *-bottom</li>
                <li>blank - for classes that set a margin or padding on all 4 sides of the element</li>
              </ul>
              <p class="mb-10">Where <code>{size}</code> is the value for the space you need: <code>2</code>: 2px, <code>4</code>: 4px, <code>6</code>: 6px, <code>8</code>: 8px, <code>10</code>: 10px, <code>12</code>: 12px, <code>16</code>: 16px, <code>20</code>: 20px, <code>30</code>: 30px, <code>40</code>: 40px, <code>50</code>: 50px</p>

              <p>Where <code>{breakpoint}</code> is one of: <code>xs</code>, <code>sm</code>, <code>md</code>, <code>lg</code>, <code>xl</code>, and <code>xxl</code></p>

              <hr>

              <h3 class="mb-10">Width and height</h3>
              
            </section>
          </b-tab-item>
      </b-tabs>

  </section>
</template>


<script>
// import ModalRoot from '../components/Modals/ModalRoot.vue';
// import ModalService from '../components/Modals/modal.service';
// import ApiKeyModal from '../components/UserProfile/ApiKeyModal';
// import { mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: "docPages",
  //components: { ModalRoot },
  // computed: {
  //   ...mapGetters(['getAllApiKeys']),
  //   ...mapState(['keys_ls']),
  //   passwordsNotMatch: function() {
  //     return this.newPassword !== this.confirmNewPassword;
  //   }
  // },
  // methods: {
  //   ...mapActions(['listAllApiKeys', 'deleteManyApiKeys', 'updateUserPassword']),

  //   toggleModal() {
  //     ModalService.open(ApiKeyModal);
  //   },
  // },

  data() {
    return {
      // Vertical tabs
      expanded: false,
      atRight: false,
      size: null,
      type: null,
      animated: false
      
    }
  },
}

</script>

<style scoped>
</style>
