import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);


var en = require('../../locales/en.json');

const messages = {
  en: {...en},

}


const i18n = new VueI18n({
  locale: 'en',
  messages,
  fallbackLocale: 'en'
})

export default i18n;
