// -*- coding: utf-8 -*-
// @author: Eric Melcher [em], Created on 01/09/2021
// IMPORTS -------------------------------------------------------------------------------------------------------------

// import ALL from "./consts"
import Dashboard from "../../views/Dashboard";
import Instruments from "../../views/Instruments";
import Portfolios from "../../views/Portfolios";
import Modules from "../../views/Modules";
import Systems from "../../views/Systems";
import CreateAccount from "../../views/CreateAccount";
import ResetPassword from "../../views/ResetPassword";
import Home from "../../views/Home";
import Login from "../../views/Login";
import Ping from "../../views/PingPong";
import UserProfile from "../../views/UserProfile";
import docPages from "../../views/docPages"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      authorize: false,
      bodyClass: 'hp-logged-out homepage pt-0'
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      authorize: false,
      bodyClass: 'pt-0'
    }
  },
  {
    path: "/ping-pong",
    name: "PingPong",
    component: Ping,
    meta: {
      authorize: false
    }
  },

  {
    path: '/create-account',
    name: 'CreateAccount',
    component: CreateAccount,
    meta: {
      authorize: false,
      bodyClass: 'pt-0'
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      authorize: false,
      bodyClass: 'pt-0'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      authorize: false
    }
  },
  {
    path: '/instruments',
    name: 'Instruments',
    component: Instruments,
    meta: {
      authorize: true
  }
  },
  {
    path: '/portfolios',
    name: 'Portfolios',
    component: Portfolios,
    meta: {
      authorize: true
  }
  },
  {
    path: '/modules',
    name: 'Modules',
    component: Modules,
    meta: {
      authorize: false
    }
  },
  {
    path: '/systems',
    name: 'Systems',
    component: Systems,
    meta: {
      authorize: true
    }
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      authorize: false
    }
  },
  {
    path: '/doc-pages',
    name: 'docPages',
    component: docPages,
    meta: {
      authorize: false
    }
  },
];

export default routes;


  // {
  //   name: "Home",
  //   path: "/",
  //   component: () => import("@/components/ui/Home.vue"),
  //   meta: {
  //     authorize: ALL
  //   },
  // },
