<template>
  <section class="section is-medium">

    <b-button label="Refresh Portfolios" icon-left="close" class="btn btn-primary" @click="listAllPortfolios"/>
      <b-table
          :data="getAllPortfolios"
          :checked-rows.sync="checkedRows"
          checkable
          :checkbox-position="checkboxPosition"
      >
        <template v-for="column in columns">
          <b-table-column :key="column._id" v-bind="column">
            <template v-if="column.searchable && !column.numeric" #searchable="props">
              <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder="Search..."
                  icon="magnify"
                  size="is-small"
              />
            </template>

            <template v-slot="props">
              {{ props.row[column.field] }}
            </template>

          </b-table-column>
        </template>

      </b-table>

  </section>
</template>

<script>
import { mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: "UserProfile",
  components: {},
  computed: {
    ...mapGetters(['getAllPortfolios']),
    ...mapState(['portfolios_ls'])
  },
  methods: {
    ...mapActions(['listAllPortfolios']),
  },

  data() {
    return {
      checkedRows: [],
      checkboxPosition: 'left',
      columns: [

        {
          field: '_id',
          label: '_id',
          searchable: true,
        },
        {
          field: 'owner',
          label: 'User _id',
          searchable: true,
        },
        {
          field: 'exchange_broker',
          label: 'Exchange & Broker',
          searchable: true,
        },
        {
          field: 'balance',
          label: 'Balance',
        },
        {
          field: 'namey',
          label: 'Name',
        },
      ],
    }
  },
}
</script>

<style scoped>
</style>
