// -*- coding: utf-8 -*-
// @author: Eric Melcher [em], Created on 08/01/2022
// IMPORTS -------------------------------------------------------------------------------------------------------------
import {api} from './api';

const END_POINT = 'portfolios';

const ALL = '';

class PortfoliosService{

    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    listAllPortfolios() {
        return api.get(`${END_POINT}/${ALL}`)
    }

}

export default new PortfoliosService(END_POINT)

