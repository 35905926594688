import {api, fast} from './api';


const END_POINT = 'default';
const PING_DJANGO = 'ping';
const PING_FAST = 'ping';


class DefaultService{

    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    pingDjango(){
        console.log('sending ping to Django backend')
        return api.get(`${PING_DJANGO}`)
    }

    pingFast(){
        console.log('sending ping to fastApi backend')
        return fast.get(`${PING_FAST}`)
    }

}

export default new DefaultService(END_POINT)